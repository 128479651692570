import { useCardType, useDocumentTitle, useScreenSize } from '@geoverse/hooks';
import {
  Button,
  Col,
  Icon,
  Image,
  Input,
  Message,
  Popover,
  Row,
  Spinner,
  Text,
} from '@geoverse/ui';
import numeral from 'numeral';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import QRCode from 'react-qr-code';

import creditCardImage from '../../../assets/images/credit-card.png';
import upiImage from '../../../assets/images/upi.png';
import { GEOPayLogo, MastercardLogo, VisaLogo } from '../../../assets/logos';
import { FlagUAIcon, Link } from '../../components/common';
import { LanguageDropdown } from '../../components/features';
import { useInvoice, useInvoicePay, useInvoicePayConfirm } from '../../hooks';

interface FormData {
  cardNumber: string;
  expirationDate?: string;
  cvv?: string;
  phoneNumber?: string;
}

type InvoicePageParams = 'id';

export const InvoicePage = () => {
  const { t } = useTranslation();

  const { id } = useParams<InvoicePageParams>();

  const { isLessThanMediumScreenSize, isLessThanLargeScreenSize } =
    useScreenSize();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    trigger,
    formState: { errors, isSubmitted },
  } = useForm<FormData>();

  const cardNumber = watch('cardNumber');

  const { cardType } = useCardType(cardNumber);

  const {
    data: invoice,
    error: invoiceError,
    isLoading: invoiceIsLoading,
    isSuccess: invoiceIsLoaded,
  } = useInvoice(Number(id));

  const isStatusError = useMemo(
    () =>
      invoice?.paymentSystemStatus !== 'INIT' &&
      invoice?.status !== 'INIT' &&
      invoice?.status !== 'PENDING',
    [invoice]
  );

  const invoiceAmount = useMemo(() => invoice?.amount.toString(), [invoice]);

  const {
    data: payInvoiceData,
    error: payInvoiceError,
    mutateAsync: payInvoice,
    isLoading: isPaying,
  } = useInvoicePay();

  const { mutateAsync: payInvoiceConfirm } = useInvoicePayConfirm();

  const isPayError = useMemo(
    () =>
      payInvoiceError?.response?.data.detail.length || payInvoiceData?.error,
    [payInvoiceData?.error, payInvoiceError?.response?.data.detail.length]
  );

  const [payInvoiceErrorTemp, setPayInvoiceErrorTemp] =
    useState(payInvoiceError);

  const [invoiceCardPan, setInvoiceCardPan] = useState<string | null>(null);

  const documentTitle = useMemo(
    () =>
      `${t('invoice:title', {
        amount: numeral(parseFloat(invoiceAmount ?? '0')).format('0,0[.]00'),
        currency: invoice?.currency.code ?? '',
      })}`,
    [invoice?.currency, invoiceAmount, t]
  );

  useDocumentTitle(documentTitle);

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const spacer = ' ';

    const inputValue = e.currentTarget?.value
      .replace(/\D/g, '')
      .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);

    if (!inputValue) return;
    if (!e.currentTarget || !e.currentTarget?.value) return;

    const value = !inputValue[2]
      ? inputValue[1]
      : `${inputValue[1]}${spacer}${inputValue[2]}${`${inputValue[3] ? `${spacer}${inputValue[3]}` : ''
      }`}`;

    setValue('phoneNumber', value);
  };

  const validateCardExpire = (value: string) => {
    if (value.length > 0 && value.replace('/', '').length !== 4) {
      return t('common:errors.inputs.expirationDate.invalid') as string;
    }

    return undefined;
  };

  const validateCardCVV = (value: string) => {
    if (value.length > 0 && value.replace('/', '').length !== 3) {
      return t('common:errors.inputs.cvv.invalid') as string;
    }

    return undefined;
  };

  const validatePhoneNumber = (value: string) => {
    if (value.length > 0 && value.replaceAll(' ', '').length !== 10) {
      return t('common:errors.inputs.phoneNumber.invalid');
    }

    return undefined;
  };

  const onSubmitConfirm = async () => {
    if (!id) return;

    await payInvoiceConfirm({ id });
  };

  const onSubmit = async (data: FormData) => {
    const cardNumber = data.cardNumber.replace(/\s/g, '');
    const cardExpireMonth = data.expirationDate
      ? data.expirationDate.split('/')[0]
      : null;
    const cardExpireYear = data.expirationDate
      ? data.expirationDate.split('/')[1]
      : null;
    const cardCVV = data.cvv;
    const phoneNumber = data.phoneNumber
      ? `38${data.phoneNumber.replace(/\s/g, '')}`
      : null;

    if (!id) return;

    const { url, card_pan } = await payInvoice({
      id,
      cardNumber,
      cardExpireMonth,
      cardExpireYear,
      cardCVV,
      phoneNumber,
    });

    if (url) {
      window.location.href = url;
    }
    if (card_pan) {
      setInvoiceCardPan(card_pan);
    }
  };

  const validateCardNumber = (value: string) => {
    const detailError = payInvoiceErrorTemp?.response?.data.detail[0];

    if (
      (value.length > 0 && value.replace(/\D/g, '').length !== 16) ||
      detailError?.loc.includes('card_pan')
    ) {
      return t('common:errors.inputs.cardNumber.invalid');
    }

    return undefined;
  };

  const renderDepositCard = () => (
    <div style={{display: 'flex', flexDirection: 'column'}}>
      <Text
        className="flex"
        variant={'headline'}
        style={{ justifyContent: 'space-around', alignItems: 'center' }}
        weight="bold"
      >
        {t('invoice:detail.how_to_pay')}
      </Text>
      <Text className="mt-3 flex" variant={'base'}>
        {t('invoice:detail.copy_card_pan')}
      </Text>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          borderRadius: '8px',
          borderWidth: '1px',
          borderColor: '#CACBCE',
          backgroundColor: '#F3F4F6',
          alignItems: 'center',
          paddingTop: 5,
          paddingBottom: 5,
          paddingLeft: 10,
          marginTop: 20,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            width: 18,
            height: 18,
            borderRadius: '50%',
            fontSize: 14,
            borderColor: 'black',
            borderWidth: 1,
            marginRight: 10,
            marginLeft: 5,
            paddingBottom: 1,
          }}
        >
          !
        </div>
        <Text variant="base">{t('invoice:detail.dont_add_comment')}</Text>
      </div>
      <Text className="mt-4 flex" variant={'base'}>
        {t('invoice:detail.click_paid')}
      </Text>

      <div
        className="mb-4 ml-auto mr-auto mt-4 h-[1px] w-full md:w-2/3"
        style={{
          backgroundImage:
            'linear-gradient(90deg, rgba(179, 179, 179, 0) -0.27%, #A6A6A6 54%, rgba(183, 183, 183, 0) 100%)',
        }}
      />

      <div
        style={{
          display: 'flex',
          flexDirection: isLessThanMediumScreenSize ? 'column' : 'row',
          justifyContent: 'space-between',
          alignItems: isLessThanMediumScreenSize ? 'start' : 'center',
          marginTop: 20,
        }}
      >
        <div style={{ flex: 2, flexDirection: 'column', display: 'flex' }}>
          <Text variant="base">{t('invoice:detail.card_number')}</Text>
          <Text
            variant="headline"
            weight="bold"
            style={{ marginTop: 3, display: 'flex', alignItems: 'center' }}
          >
            {invoiceCardPan &&
              invoiceCardPan
                .split('')
                .map((char, index) =>
                  index > 0 && index % 4 === 0 ? ` ${char}` : char
                )}
            {invoiceCardPan && (
              <Icon.Copy size={20} className="ml-1">
                {invoiceCardPan.toString()}
              </Icon.Copy>
            )}
          </Text>
        </div>
        <div
          style={{
            flex: 1,
            flexDirection: 'column',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Text variant="base">{t('invoice:detail.amount')}</Text>

          <Text
            variant="headline"
            weight="bold"
            style={{ marginTop: 3, display: 'flex', alignItems: 'center' }}
          >
            {numeral(
              Number(
                invoiceAmount?.indexOf('.') !== -1
                  ? invoiceAmount?.substring(0, invoiceAmount?.indexOf('.'))
                  : invoiceAmount
              )
            ).format('0,0') ?? 0}
            {numeral(Number(invoiceAmount)).format('.00') ?? 0}
            {invoiceAmount && (
              <Icon.Copy size={20} className="ml-1">
                {invoiceAmount.toString()}
              </Icon.Copy>
            )}
            <Text
              variant="base"
              weight="normal"
              style={{
                marginTop: 3,
                marginLeft: 3,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {invoice?.currency?.code}
            </Text>
          </Text>
        </div>
      </div>

      <Button
        onClick={() => {
          onSubmitConfirm();
          window.open(invoice?.comment?.successUrl, '_self');
        }}
        block
        className="mt-5"
        type="submit"
        style={{ backgroundColor: '#403F3F', color: '#fff' }}
      >
        {t('common:buttons.paid')}
      </Button>
    </div>
  );

  const renderCardLogo = (_cardType: typeof cardType) => {
    switch (_cardType) {
      case 'VISA':
        return <VisaLogo />;
      case 'MASTERCARD':
        return <MastercardLogo />;
      default:
        return <Icon.CreditCard />;
    }
  };

  const renderForm = () =>
    invoiceIsLoading ? (
      renderFormPlaceholder()
    ) : (
      <form onSubmit={handleSubmit(onSubmit)} className="w-full">
        <Col align="stretch">
          <Col className="mb-6">
            <div className="flex flex-col gap-4 lg:flex-row lg:items-start">
              <Col flex={1} align="stretch">
                <Input.CardNumber
                  label={t('common:labels.cardNumber')}
                  placeholder={t('common:placeholders.cardNumber')}
                  RightComponent={renderCardLogo(cardType)}
                  rightComponentClassName={cardType ? '' : '!text-gray-300'}
                  error={errors?.cardNumber?.message}
                  {...register('cardNumber', {
                    required: t('common:errors.inputs.cardNumber.required'),
                    validate: (value) => validateCardNumber(value),
                  })}
                />
              </Col>
              <Col>
                <Row gap="4" wrap={false}>
                  <Col flex={isLessThanLargeScreenSize ? 1 : 'none'}>
                    <Input.CardExpire
                      label={t('common:labels.expirationDate')}
                      placeholder={t('common:placeholders.expirationDate')}
                      className="lg:w-24"
                      error={errors?.expirationDate?.message}
                      errorMessageVisible={isLessThanLargeScreenSize}
                      {...register('expirationDate', {
                        required: t(
                          'common:errors.inputs.expirationDate.required'
                        ),
                        validate: (value) =>
                          value ? validateCardExpire(value) : undefined,
                      })}
                    />
                  </Col>
                  <Col flex={isLessThanLargeScreenSize ? 1 : 'none'}>
                    <Input.CardCVV
                      label={t('common:labels.cvv')}
                      placeholder={t('common:placeholders.cvv')}
                      className="lg:w-[88px]"
                      error={errors?.cvv?.message}
                      errorMessageVisible={isLessThanLargeScreenSize}
                      RightComponent={
                        <Popover
                          className="bottom-[43px] right-[-7px] w-[294px] lg:right-[-54px]"
                          Trigger={
                            <Icon.QuestionMarkCircleSolid className="mt-1.5 cursor-default !text-gray-300 outline-none" />
                          }
                        >
                          <Row justify="center">
                            <Image
                              src={creditCardImage}
                              alt="Credit Card image"
                              width="216px"
                            />
                          </Row>
                          <Text
                            variant="small"
                            className="inline-block italic text-gray-600"
                          >
                            {t('common:texts.cvv')}
                          </Text>
                        </Popover>
                      }
                      {...register('cvv', {
                        required: t('common:errors.inputs.cvv.required'),
                        validate: (value) =>
                          value ? validateCardCVV(value) : undefined,
                      })}
                    />
                  </Col>
                </Row>
                {!isLessThanLargeScreenSize &&
                  (errors.expirationDate || errors.cvv) && (
                    <Col>
                      <Text
                        variant="small"
                        className="ml-1 mt-0.5 !text-red-500"
                      >
                        {errors?.expirationDate?.message ||
                          errors?.cvv?.message}
                      </Text>
                    </Col>
                  )}
              </Col>
            </div>
          </Col>
          <Input
            label={t('common:labels.phoneNumber')}
            LeftComponent={<FlagUAIcon />}
            placeholder={t('common:placeholders.phoneNumber')}
            className="!pl-11"
            error={errors?.phoneNumber?.message}
            {...register('phoneNumber', {
              required: t('common:errors.inputs.phoneNumber.required'),
              validate: (value) =>
                value ? validatePhoneNumber(value) : undefined,
              onChange: handlePhoneNumberChange,
            })}
          />
        </Col>
        {isPayError ? renderPayError() : null}
        <Button
          block
          className="mt-14"
          type="submit"
          loading={isPaying}
          disabled={isPaying || !invoiceIsLoaded || isStatusError}
        >
          {t('common:buttons.pay')}
        </Button>
      </form>
    );

  const renderWhiteExchangeForm = () =>
    invoiceIsLoading ? (
      renderFormPlaceholder()
    ) : (
      <form onSubmit={handleSubmit(onSubmit)} className="w-full">
        <Col align="stretch">
          <Input.CardNumber
            label={t('common:labels.cardNumber')}
            placeholder={t('common:placeholders.cardNumber')}
            RightComponent={renderCardLogo(cardType)}
            rightComponentClassName={cardType ? '' : '!text-gray-300'}
            error={errors?.cardNumber?.message}
            {...register('cardNumber', {
              required: t('common:errors.inputs.cardNumber.required'),
              validate: (value) => validateCardNumber(value),
            })}
          />
        </Col>
        {isPayError ? renderPayError() : null}
        <Button
          block
          className="mt-8"
          type="submit"
          loading={isPaying}
          disabled={isPaying || !invoiceIsLoaded || isStatusError}
          style={{ backgroundColor: '#403F3F', color: '#fff' }}
        >
          {t('common:buttons.pay')}
        </Button>
      </form>
    );

  const renderGoldenWoodForm = () =>
    invoiceIsLoading ? (
      renderFormPlaceholder()
    ) : (
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <Text weight="bold" variant='h5'>
          {t('common:labels.scanQR')}
        </Text>
        <div style={{ maxWidth: 200, maxHeight: 200, marginTop: 10, marginBottom: 20 }}>
          <QRCode
            size={256}
            style={{ height: "auto", maxWidth: "100%", width: "100%", cursor: 'pointer' }}
            value={invoice?.comment?.upi ?? ''}
            viewBox={`0 0 256 256`}
            onClick={() => {
              window.open(invoice?.comment?.upi, '_self');
            }}
          />
        </div>
      </div>
    );

  const renderFormPlaceholder = () => (
    <Col align="stretch" justify="center" className="mt-4 w-full" gap="11">
      <div className="flex flex-col gap-11 lg:flex-row lg:gap-4">
        <div className="h-10 w-full animate-pulse rounded-lg bg-gray-200 lg:w-1/2" />
        <div className="flex flex-row gap-4 lg:w-1/2">
          <div className="h-10 w-1/2 animate-pulse rounded-lg bg-gray-200 lg:w-1/2" />
          <div className="h-10 w-1/2 animate-pulse rounded-lg bg-gray-200 lg:w-1/2" />
        </div>
      </div>
      <div className="h-10 w-full animate-pulse rounded-lg bg-gray-200" />
      <div className="mt-3 h-10 w-full animate-pulse rounded-lg bg-gray-200" />
    </Col>
  );

  const renderStatusError = () => (
    <Col align="center" justify="center" className="w-full" gap="6">
      <Icon.ExclamationTriangle className="!text-red-500" size={72} />
      <Message color="red">{getStatusErrorMessage()}</Message>
      <Button
        block
        style={{ backgroundColor: '#403F3F', color: '#fff' }}
        variant="outline"
        onClick={() => {
          window.open(invoice?.comment?.failUrl, '_self');
        }}
      >
        {t('common:buttons.back')}
      </Button>
    </Col>
  );

  const renderFooter = () => (
    <Col flex="none" align="center" className="bg-gray-200 py-7 md:py-0">
      <Row justify="between"></Row>
    </Col>
  );

  const renderPayError = () => {
    const messageOrI18Key = getPayErrorMessage();

    let content;

    if (messageOrI18Key?.startsWith('invoice:')) {
      content = (
        <Col>
          <Trans
            i18nKey={messageOrI18Key}
            components={{
              br: <br />,
              ul: <ul />,
              li: <li className="marker:text-red-500" />,
            }}
          />
        </Col>
      );
    } else {
      content = messageOrI18Key;
    }

    return (
      content && (
        <div className="prose prose-gray mt-8 dark:prose-invert">
          <Message color="red">{content}</Message>
        </div>
      )
    );
  };

  const getStatusErrorMessage = () => {
    let message = invoiceError?.response?.data.detail;

    switch (invoice?.status) {
      case 'EXPIRED':
      case 'REJECT':
      case 'PENDING':
      case 'HOLD':
      case 'DONE':
        message = t(`invoice:statuses.${invoice?.status}`);
        break;
      default:
        message = invoiceError?.response?.data.detail;
        break;
    }

    return message;
  };

  const getPayErrorMessage = () => {
    let message;

    switch (payInvoiceData?.error) {
      case 'EXPIRED':
      case 'DUPLICATE':
      case 'ANTI_FRAUD':
      case 'EMPTY_RESPONSE':
      case 'INVALID_OTP_CODE':
      case 'ISSUER_UNAVAILABLE':
      case 'INSUFFICIENT_FUNDS':
      case 'AMOUNT_IS_EXCEEDED':
      case 'CARD_NOT_3DS_ENROLLED':
      case 'CARD_EXPIRED':
      case 'LIMIT_VIOLATION':
      case 'INVALID_CARD_NUMBER':
      case 'INVALID_CVV':
      case 'INVALID_EXPIRE_DATE':
      case 'TRANSACTION_NOT_FOUND':
        message = `invoice:errors.${payInvoiceData?.error}`;
        break;
      default:
        message = payInvoiceData?.error;
        break;
    }

    return message;
  };

  useEffect(() => {
    if (!cardNumber?.length) return;

    setPayInvoiceErrorTemp(null);
    setTimeout(() => {
      trigger('cardNumber');
    });
  }, [cardNumber, isSubmitted, trigger]);

  useEffect(() => {
    setPayInvoiceErrorTemp(payInvoiceError);
    setTimeout(() => {
      trigger('cardNumber');
    });
  }, [payInvoiceError, trigger]);

  useEffect(() => {
    if (invoice?.paymentSystem?.name == 'PAY_PAY' && invoice?.comment?.html) {
      const form = document.querySelector('form');
      if (form) {
        form.submit();
      }
    }

  }, [invoice]);

  if (invoice?.paymentSystem?.name == 'PAY_PAY' && invoice?.comment?.html) {
    return (
      <div dangerouslySetInnerHTML={{ __html: invoice?.comment?.html }} />
    )
  }

  if (invoiceIsLoading) {
    return (
      <Row align="center" justify="center">
        <Spinner size="large" className="my-5" />
      </Row>
    )
  }

  return (
    <div className="flex min-h-screen flex-col md:flex-row">
      <div className="z-10 flex-1 pb-10 pt-4 md:pt-12 lg:pb-28">
        <Col className="h-full items-center px-6 lg:px-16 xl:items-end xl:pr-40">
          <div className="h-full w-full max-w-[416px]">
            <Col align="stretch" justify="between" className="h-full">
              <Row
                align="stretch"
                justify="between"
                className="direction-row w-full flex-none justify-center"
              >
                <Link
                  external
                  to={invoice?.comment?.failUrl}
                  className="mb-20 w-max"
                >
                  <Row>
                    <Icon.ArrowLeftCircleSolidIcon
                      size={20}
                      className="mr-2.5 !text-gray-700"
                    />
                    <Text>{t('common:buttons.back')}</Text>
                  </Row>
                </Link>
                {isLessThanMediumScreenSize && <LanguageDropdown />}
              </Row>

              <Col align="stretch" className="justify-start 2xl:justify-center">
                <Row align="start" className="mb-0 flex-none md:mb-5">
                  <Row align="center" justify="center">
                    {invoice?.paymentSystem.name === 'GOLDEN_WOOD'
                      ? <Image
                        src={upiImage}
                        alt="UPI image"
                        width="240px"
                      />
                      : <GEOPayLogo width="240" height="80" />
                    }
                  </Row>
                </Row>

                {invoice?.paymentSystem.name !== 'GOLDEN_WOOD' && (
                  <Row align="start" className="mb-0 flex-none md:mb-5">
                    <Row align="center" justify="center">
                      <Text variant="headline" weight="bold">
                        {t('invoice:description')}
                      </Text>
                    </Row>
                  </Row>)}

                <Row align="start" className="mb-0 flex-none md:mb-5">
                  <Row align="center" justify="center">
                    <div
                      className="mt-1 h-[1px] w-full md:w-2/3"
                      style={{
                        backgroundImage:
                          'linear-gradient(90deg, rgba(179, 179, 179, 0) -0.27%, #A6A6A6 54%, rgba(183, 183, 183, 0) 100%)',
                      }}
                    />
                  </Row>
                </Row>

                <Col flex="none" align="center" className="mb-10 md:mb-28">
                  {invoiceIsLoading ? (
                    <Row align="center" justify="center">
                      <Spinner size="large" className="my-5" />
                    </Row>
                  ) : (
                    <Col align="center" gap="1">
                      <Row align="end" justify="center" className="flex-none">
                        <Text variant="headline" weight="bold">
                          {numeral(
                            Number(
                              invoiceAmount?.indexOf('.') !== -1
                                ? invoiceAmount?.substring(
                                  0,
                                  invoiceAmount?.indexOf('.')
                                )
                                : invoiceAmount
                            )
                          ).format('0,0') ?? 0}
                        </Text>
                        <Text variant="lead" weight="bold">
                          {numeral(Number(invoiceAmount)).format('.00') ?? 0}
                        </Text>
                        <Text variant="headline" weight="bold" className="pl-2">
                          {invoice?.currency?.code}
                        </Text>
                      </Row>
                      <Row
                        align="center"
                        justify="center"
                        className="text-gray-500"
                      >
                        <Text color="inherit">{`ID: ${invoice?.id ?? '—'
                          }`}</Text>
                        {invoice?.id && (
                          <Icon.Copy size={20} className="ml-1">
                            {invoice?.id.toString()}
                          </Icon.Copy>
                        )}
                      </Row>
                    </Col>
                  )}
                </Col>
              </Col>
              {!isLessThanMediumScreenSize && renderFooter()}
            </Col>
          </div>
        </Col>
      </div>
      <div className="shadow-80 md:shadow-40 z-20 flex-1 rounded-t-3xl bg-page-light pt-8 md:rounded-none md:pt-12">
        <Col align="stretch" className="h-full" justify="between">
          {!isLessThanMediumScreenSize && (
            <Row
              align="center"
              className="mb-5 w-full flex-none justify-center px-8 lg:px-16 xl:justify-start xl:pl-40"
            >
              <Row justify="end" className="max-w-[500px]">
                <LanguageDropdown />
              </Row>
            </Row>
          )}
          <Col className="items-center px-8 pb-20 md:pb-10 lg:px-16 lg:pb-28 xl:items-start xl:pl-40 2xl:justify-center">
            <div className="w-full max-w-[500px]" style={{ display: 'flex', justifyContent: 'center' }}>
              {
                isStatusError && !invoiceIsLoading
                  ? renderStatusError()
                  : invoiceCardPan
                    ? renderDepositCard()
                    : invoice?.paymentSystem.name === 'WHITE_EXCHANGE'
                      ? renderWhiteExchangeForm()
                      : invoice?.paymentSystem.name === 'GOLDEN_WOOD'
                        ? renderGoldenWoodForm()
                        : renderForm()
              }
            </div>
          </Col>
          {!isLessThanMediumScreenSize && renderFooter()}
        </Col>
      </div>
    </div>
  );
};
