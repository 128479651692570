import { Link as BaseLink, LinkProps as BaseLinkProps } from '@geoverse/ui';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  To,
  useLocation,
} from 'react-router-dom';

/* ========================================================================== */
/*                                    Props                                   */
/* ========================================================================== */

export type LinkProps = BaseLinkProps &
  Omit<RouterLinkProps, 'to'> & {
    to?: To;
    external?: boolean;
  };

/* ========================================================================== */
/*                                  Component                                 */
/* ========================================================================== */

export const Link = ({ external, to, ...rest }: LinkProps) => {
  const { search } = useLocation();

  return external || !to ? (
    <BaseLink
      href={to ? (to as string) : undefined}
      target="_blank"
      rel="noreferrer"
      {...rest}
    />
  ) : (
    <BaseLink to={to + search} as={RouterLink} {...rest} />
  );
};
